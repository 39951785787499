import request from '@/utils/requests';

const getters = {
  getExchanges: (state) => state.exchanges,
  getExchange: (state) => state.exchange,
};
const mutations = {
  setExchanges: (state, exchanges) => {
    state.exchanges = exchanges;
  },
  setExchange: (state, exchange) => {
    state.exchange = exchange;
  },
};

const actions = {
  fetchExchanges: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        'api/exchanges',
        rootGetters['auth/getToken'],
        {},
        payload.params,
      );
      commit('setExchanges', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  fetchExchangeById: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'GET',
        `api/exchanges/${payload.exchange.id}`,
        rootGetters['auth/getToken'],
        {},
        {},
      );
      commit('setExchange', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  updateExchange: async ({ commit, rootGetters }, payload) => {
    try {
      const response = await request(
        'PUT',
        `api/exchanges/${payload.exchange.id}`,
        rootGetters['auth/getToken'],
        payload.exchange,
        {},
      );
      commit('setExchange', response.data);
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  rateExchange: async ({ rootGetters }, payload) => {
    try {
      const response = await request(
        'POST',
        `api/exchanges/${payload.exchange.id}/feedback`,
        rootGetters['auth/getToken'],
        rootGetters['demo/isDemoApp'] ? payload : payload.review,
        {},
      );
      return Promise.resolve(response);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};

const modules = {};
const state = {
  exchange: {},
  exchanges: {},
};

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  modules,
  state,
};
